import React, { Component } from "react"
import Layout from "../../components/layout"

import VerticalApplications from "../../components/sections/verticalApplications/verticalApplications"
import CTABanner from "../../components/common/ctaBanner/ctaBanner"
import homeBannerImg3 from "../../../assets/home-banner-img3.png"
import * as styles from "./ironcap-toolkits.module.scss"
import chat from "../../../assets/support-chat.png"
import HowItWorks from "../../components/sections/howItWorks/howItWorks"
import ProgressiveImage from "react-progressive-image-loading"
import banner3bg from "../../../assets/home-banner3.png"
import banner3bgmin from "../../../assets/home-banner3-min.png"
import { Helmet } from "react-helmet"
import { LearnMoreTagline } from "../../components/common/typography/banner-tagline"
import { TM } from "../../components/common/typography/trademark"
import { getStringWithTrademark } from "../../utils/get-string-with-trademark"

const applicationsText = (
  <>
    Our IronCAP
    <TM /> Toolkits can be used by vendors, for example, to build highly secure
    systems for blockchain security, IoT devices, data storage, remote access,
    encryption, digital signing, etc. Our NIST-approved post-quantum
    cryptography (PQC) technologies can protect our customers against
    ever-evolving illegitimate and malicious means of gaining access to their
    data, not only from computers today but also from quantum computers in the
    future. To ensure seamless integration, our IronCAP
    <TM /> Toolkits is compliant with the OpenSSL, PKCS#11 and OpenPGP (RFC4880)
    industry standards.
  </>
)

// const stringifiedTrademarkedApplicationsText = getStringWithTrademark(
//   trademarkedApplicationsText
// )

const trademarkedIronCAP = (
  <>
    IronCAP
    <TM /> Toolkits
  </>
)

const stringifiedTrademarkedIronCAP = getStringWithTrademark(trademarkedIronCAP)

class IronCAPAPI extends Component {
  constructor() {
    super()
  }
  openChat = () => {
    window.open(
      "http://www.imoncall.com/ironcapchat",
      "IronCAP Live Chat",
      "width=500,height=600"
    )
  }
  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`${stringifiedTrademarkedIronCAP}`}</title>
          <link
            rel="canonical"
            href="https://www.ironcap.ca/ironcap-toolkits"
          />
        </Helmet>
        <div id={styles.carouselContainer}>
          <ProgressiveImage
            preview={banner3bgmin}
            src={banner3bg}
            transitionTime={500}
            transitionFunction="ease"
            render={(src, style) => (
              <div
                className={styles.carouselGraphic3}
                style={Object.assign(style, { backgroundImage: `url(${src})` })}
              >
                <div className={styles.carouselText}>
                  <h4>Post-Quantum</h4>
                  <h3>
                    IronCAP
                    <TM /> Toolkits
                  </h3>
                  <p>
                    Our IronCAP
                    <TM /> Toolkits allows vendors of a wide variety of vertical
                    applications to easily transform their products to ensure
                    safety against cyber attacks from not only the classical
                    world of computers today but also against future attacks
                    from quantum computers.
                  </p>
                  <a target="_blank" onClick={this.openChat}>
                    <button className={styles.liveChatButton}>
                      <img src={chat} alt="Pre-sales chat" />
                      <div className={styles.chatText}>
                        <p>Live Chat</p>
                        <p>Pre-Sales</p>
                      </div>
                    </button>
                  </a>
                </div>
                <img
                  className={styles.carouselImg}
                  src={homeBannerImg3}
                  alt="Third slide"
                  style={{
                    width: "45%",
                    maxWidth: "580px",
                    marginRight: "13%",
                  }}
                />
              </div>
            )}
          />
        </div>
        <VerticalApplications applicationsText={applicationsText} />
        <HowItWorks />
        <CTABanner
          tagline={LearnMoreTagline}
          CTA="video"
          CTADetail="how video"
        />
      </Layout>
    )
  }
}

export default IronCAPAPI
